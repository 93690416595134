
.App {
  text-align: center;
   
}
.App_booking{
  height: 2000px;
  display: flex;
   margin: 0;
   padding: 0;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
}
.guideNewHolder{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loadingCircle{
  
}
.booking_left{
  width: 60%;
  height: 100%;
  

 
}
.booking_right{
  width: 40%;
  height: 100%;
  position: relative;
  
}
.bl_1 > p{
  font-weight: 600;
  text-align: left;
  font-size: 30px;
}
.bl_1{
  width: 100%;
  height: 120px;
 
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.bl_2{
  width: 100%;
  height: 80px;
  
  display: flex;
  justify-content: flex-start;
  justify-content: flex-start;
}
.bl_3{
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.134);
}
.bl_4{
  margin-top: 50px;
  width: 100%;
  height: 400px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.134);
  text-align: left;
}
.bl_55{
  margin-top: 50px;
  width: 100%;
  height: 500px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.134);
  text-align: left;
}
.bl4_holder{
  width: 100%;
  height: 75px;
  margin-top: 50px;
  display: flex;
  
  justify-content: space-between;
}
.c_center{
  width: 100%;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);

}.c_center > h1{
  color: rgba(0, 0, 0, 0.67);
 
}
.c_center > p{
  color: rgba(0, 0, 0, 0.4);
 font-size: 17px;
 font-family: 'Roboto';
 width: 700px;
}
.c_center > img{
  width: 200px;
  height: 200px;
  margin-left: 25px;
}
.c_button{
  margin-top: 25px;
  width: 250px;
  height: 50px;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  font-size: 20px;
  color: white;
  transition: 0.5s;
  font-family: 'Roboto';
 
}
@keyframes scaleAnimation {
  0% { transform: scale(1); } /* Start scale */
  100% { transform: scale(1.1); } /* End scale */
}
.confetti{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 90vw;
}
.c_button:hover{
 transform: scale(1.1);
}
.c_button_right:hover{
  width: 350px;
  height: 75px;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  font-size: 22px;
  color: white;
}
.c_button_right{
  width: 350px;
  height: 75px;
  background-color: white;
  border: 2px solid #6F42C1;
  font-size: 22px;
  color: #6F42C1;
   transition: 0.5s;
}
.cc{
  margin-top: 50px;
  display: flex;
  width: 800px;
  height: 75px;
  justify-content: space-between;
}
.bl_5{
  margin-top: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.bl_5 > button{
  width: 100%;
  height: 75px;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  color:white;
  font-size: 20px;
  border-radius: 5px;
  transition: 0.2s;
 
}
.bl_5 > button:hover{
  cursor: pointer;
  transform: scaleY(1.1);
}
.bl_3_top{
  width: 100%;
  height: 50%;
  
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bl_3_top > p{
  font-size: 20px;
}
.bl_3_bottom{
  width: 100%;
  height: 40%;
  
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
}
.bl_2 > p{
  font-weight: 600;
  text-align: left;
  font-size: 22px;
  margin-top: 25px;
  padding: 0;
}
.book_time{
  width: 100px;
  background-color: white;
  border: 2px solid #6F42C1;
  color:#6F42C1;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.2s;
  margin-right: 25px;
}
.book_time:hover{
  width: 100px;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  color:white;
  font-size: 15px;
  border-radius: 5px;
}
.book_time_selected{
  width: 100px;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  color:white;
  font-size: 15px;
  border-radius: 5px;
}
.price_container{
  width: 25vw;
  height: 350px;
  border: 1px solid rgba(128, 128, 128, 0.3);
  position: fixed;
  top: 100px;
  right: 12vw;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.guideTopNav{
display: flex;
justify-content: space-between;
align-items: center;
width: 100vw;
height: 10vh;
border-bottom: 1px solid rgba(0, 0, 0, 0.274);
}
.pc1{
  margin-top: 20px;
  width: 80%;
  height: 40%;
 
  display: flex;
  border-bottom: 2px solid rgba(128, 128, 128, 0.134);
}
.pc1_left{
  width: 40%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.pc1_left > img{
 object-fit: cover;
 width: 100%;
 height: 70%;
 border-radius: 15px;
}
.pc1_right{
  width: 60%;
  height: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
}
.pc1_right_center{
height: 50%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
}

.pc1_right_center > p{
  margin-right: 10px;
  text-wrap: nowrap;
  }
.pc1_right_center > h3{
 height: 20px;
 margin: 0;
 padding: 0; 
 text-wrap: nowrap;
}
.pc1_right_uni{
  display: flex;
}
.pc1_right_uni > p{
  margin-right: 10px;
  text-wrap: nowrap;
  
}

.pc2{
  margin-top: 10px;
  width: 80%;
  height: 35%;
  border-bottom: 2px solid rgba(128, 128, 128, 0.134);
  font-size: 14px;
   
}
.pc2 > h2{
  text-align: left;
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
}
.pc3_price_container{
  display: flex;
  height: 35px;
  
}
.pc3{
  width: 80%;
  height: 20%;
 
}
.pc3_left{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
 
}
.pc3_right{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  
}
.pc3_total{
  color: black;
  font-size: 17px;
  font-weight: bold;
}
.pc3_text{
  font-size: 15px;
  color: gray;
}
.colored{
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  background-color: black;
}
.svgg{
  position: absolute;
  top: 0;
  left:0;
 margin: 0;
 padding: 0;
 z-index: -1;
}
.par{
  width: 100%;
  height: 7000px;
  background-color:#25224A;
  position: relative;
}
.svggg{
  position: absolute;
  bottom: 0;
  left:0;
 margin: 0;
 padding: 0;
 z-index: 1;
 pointer-events: none;
 
}
.svgggg{
  margin-top: 100px;
}
.how1text{
  color: white;
  font-size: 40px;
  margin-top: 900px;
}
.how{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}
.guideTopNavHalf{
width: 20vw;
height: 100%;
display: flex;
justify-content: center;
align-items: center;

}
.guideNewHTop{
  width: 100vw;
  height: 75vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.guideNewHBottom{
  width: 100vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.progress{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
}
.BottomNav{
  width: 30vw;
  height: 50%;
 
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottomNavLeft{
width: 25%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
color: #25224ad0;
border-radius: 10px 0px 10px 10px;
transition: 0.1s;
}
.bottomNavLeft:hover{
  background-color: #9999993d;
  cursor: pointer;
}
.bottomNavRight{
  width: 25%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: #25224A;
border-radius: 0px 10px 10px 10px;
transition: 0.25s;
z-index: 10;
}
.sign-in{
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25224A;
  margin-top: 25px;
  border-radius: 0 15px 15px 15px;
  
}
.sign-in > p{
  font-size: 15px;
  color: white;
  margin-right: 10px;
}

.blur{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(2px);
  z-index: 12;
}
.joinlink{
  width: 49%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  text-decoration: none;
color: #25224A;
border-radius: 15px;
font-size: 20px;
box-shadow: 0 15px 50px 15px #acacac41;
}
.jointext{
  font-size: 35px;
  color: #25224acb;;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, 50%);
 font-weight: 600;

}

.joinlink1{
  width: 80%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
color: #25224acd;
border-radius: 5px;
font-size: 20px;
font-weight: bold;
border: 3px solid #25224a89;
transition: 0.1s;
}
.joinlink1:hover{
  width: 80%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25224a;
  text-decoration: none;
color: white;
border-radius: 5px;
font-size: 20px;
font-weight: bold;
border: 3px solid #25224a89;
}
.joincenter{
  position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
width: 70vw;
height: 30vh;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

}
.jointext1{
  position: absolute;
  top: -30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: 600;
  width: 100%;
  
}
.sign-in-holder{
  width: 400px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -37%);
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.sign-in-holder1{
  width: 400px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sign-top{
  font-size: 30px;
  font-weight: bolder;
}
.sign-top1{
  font-size: 30px;
  font-weight: bolder;
  text-wrap: nowrap;
  margin-bottom: 50px;
  
}
.cal{
  display: flex;
  justify-content: flex-start;
  
}
.price_container{
  position: fixed;
}
.booking_container{
  width: 80vw;
  height: 100%;
 
  display: flex;
}
.g{
  
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent */
  
}
.google-button{
  width: 80%;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-button:hover{
  cursor: pointer;
}
.sign-in:hover{
  cursor: pointer;
}
.google-button-center{
  width: 60%;
  height: 100%;
  display: flex;
 
  flex-direction: row;
   justify-content: center;
   align-items: center;
}
.image-box{
  width: 20%;
  height: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-box > img{
  transform: scale(0.5);
}
.or{
  padding: 15px;
  color: gray;
 
}
.text-box{
  width: 80%;
  height: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-wrap: nowrap;
  font-weight: bold;
}

.app2{
 
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.joincenterleft{
  width: 50%;
  height: 100%;
  border-right: 3px dashed rgba(31, 38, 135, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.joincenterright{
  width: 50%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full{
  width: 100vw;
  height: 100vh;
}
.categoryChooseScroll{
  width: 90%;
  height: 310px;
  
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: none;
 
  
}
.categoryRow{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 125px;
 
  flex-shrink: none;
  min-height: 125px;
}
.bottom{
  width: 100%;
  height: 70px;
  
  position: relative;
}
.bottomRight1{
  position: absolute;
  right: 55px;
  bottom: 0;
  width: 200px;
  height: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}
.continue{
  width: 150px;
  height: 70%;
 border-radius: 5px;
  background-color: #25224A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.continue1{
  width: 150px;
  height: 70%;
 border-radius: 5px;
  background-color: #25224a57;
  display: flex;
  justify-content: center;
  align-items: center;
}
.continue > p{
  color: white;
  margin-top: 15px;
}
.continue1 > p{
  color: rgba(255, 255, 255, 0.277);
  margin-top: 15px;
}
.categoryChoice{
  width: 100px;
  height: 75%;
  border-radius: 5px;
  border: 2px dotted rgba(0, 0, 0, 0.438);
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 100;
}
.categoryChoiceSelected{
  background-color: #b694f542;
  border: 2px dotted #6F42C1;
  width: 100px;
  height: 75%;
  border-radius: 5px;
 
  display: flex;
  flex-direction: column;
}
.categoryChoice:hover{
  background-color: #b694f542;
  cursor: pointer;
}
.categoryImageHolder > img{
  width: 50px;
  height: 50px;
}

.categoryImageHolder{
  height: 65%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryTitleHolder{
  height: 35%;
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
 
}
.categoryTitleHolder > p{
  font-weight: bold;
  font-size: 12px;
}
.categoryChoose{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: white;
  z-index: 15;
  box-shadow: 0 15px 50px 15px #acacac41;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.categoryChoose > p{
  color: #25224A;
  font-size: 25px;
  width: 80%;
  font-weight: bold;
}
.categoryChoose1{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: white;
  z-index: 15;
  box-shadow: 0 15px 50px 15px #acacac41;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.categoryChoose1 > p{
  color: #25224A;
  font-size: 25px;
  width: 80%;
  font-weight: bold;
}
.guideContentHolder{
  width: 50vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;

}
.guideContentHolder11{
  width: 100%;
  height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
  align-items: flex-start;
 
}
.guideContentHolder1{
  width: 90vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;

}
.itemsToRecommend{
  background-color: white;
  width: 50%;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 15px 50px 15px #acacac41;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: none;
  z-index: 1000;
}
.offeredItemHighlighted{
  text-decoration: none;
  background-color: #b694f542;
  font-weight: 600;
  text-align: left;
 margin: 0;
 padding: 8px;
 z-index: 20;
 font-size: 14px;
 width: 100%;

}
.offeredItem{
  text-decoration: none;
  background-color:white;
  font-weight: 600;
  text-align: left;
 margin: 0;
 padding: 8px;
 z-index: 20;
 font-size: 14px;
 width: 100%;
}
.offeredItem:hover{
  background-color: #b694f542;
}
.offeredItem:focus{
  background-color: #b694f542;
}
.interests{
  width: 393px;
  height: 35px;
  border-radius: 5px;
  border: 2px solid rgba(128, 128, 128, 0.089);
  padding-left: 15px;
  font-size: 16px;
  color: gray;
}

.interests::placeholder { /* Edge 12 -18 */
  color: rgba(128, 128, 128, 0.733);
}
.selectedCategoriesHolder{
  width: 500px;
  height: 6vh;
  margin-top: 25px;
 
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  position: relative;
}
.fixedSelectedCategories{
  position: relative;
  width: 500px;
  height: 6vh;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.categoriesHolder{
  width: 500px;
  height: 40vh;
  margin-top: 10px;
 
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
 
}
.categoryBox{
  width: 100%;
 
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.categoryTitle{
 
  height: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.categoryTitle > p{
  font-weight: 700;
  color: rgb(61, 61, 61);
  z-index: 10;
}
.categoryContentHolder{
  width: 90%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  position: relative;
}
.categoryContentHolderHolder{
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.scroll{
  position:absolute;
  right: 0;
  top: 0;
  width: 1vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  
}
.scroll > img{
  width: 15px;
  height: 15px;
}
.scrollLeft{
  position:absolute;
  left: 0;
  top: 0;
  width: 1vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  
}
.scrollLeft > img{
  width: 15px;
  height: 15px;
  transform: rotateY(180deg);
  z-index: 15;
}
.categoryContentHolder::-webkit-scrollbar {
  display: none;
  height: 0px;
}
.guideContentHolder::-webkit-scrollbar{
  display: none;
  height: 0px;
}
.categoryChooseScroll::-webkit-scrollbar {
  display: none;
  height: 0px;
}
.categorySize1{
  width: 200px;
  height: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryOption{
  width: 80%;
  height: 30px;
  border: 1px solid rgba(128, 128, 128, 0.507);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryNameFirst{
  text-wrap: nowrap;
  background-color: transparent;
  color: rgb(107, 106, 106);
  border: 1px solid rgba(128, 128, 128, 0.507);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 15px;
 
 
  font-size: 14px;
}
button > span{
  font-weight: bold;
  margin-left: 5px;
  font-size: 10px;
  color: black;
}
.categoryName{
  
  text-wrap: nowrap;
  background-color: transparent;
  color: rgb(107, 106, 106);
  border: 1px solid rgba(128, 128, 128, 0.507);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
 
  border-radius: 15px;
  
  margin-left: 15px;
  font-size: 14px;
  padding-bottom: 3px;
  padding-top: 3px;
}
.categoryName:hover{
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(170, 170, 170, 0.178);
}
.categoryNameFirst:hover{
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(170, 170, 170, 0.178);
}
.fade{
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 5vh;
  position: sticky;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
}
.fade1{
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 250px;
  position: sticky;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
}
.fade2{
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
  
}
.fadeRight{
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0px;
  
}
.fadeLeft{
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
  width: 50px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0px;
  z-index: 2
  
}
.categoriesHolder::-webkit-scrollbar {
  display: none;
}
.selectedCategoriesHolder::-webkit-scrollbar {
  display: none;
}

.guideContentHeader > p{
  margin-top: 75px;
  font-size: 25px;
  color:#25224A;
  font-weight: 700;
  width: 500px;
}
.guideContentHeader{
  width: 100%;
  height: 10%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomNavRight:hover{
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(212, 212, 212, 0.603);
}
.bottomNavRightCenter{
  width: 70%;
  height: 30%;
 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}
.bottomNavRightCenter > img{
  width: 15px;
  height: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&family=Open+Sans&family=Roboto&display=swap');
.AppHome {
  text-align: center;
  overflow-x: hidden;
 padding-bottom: 20px;
  display: flex;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  flex-direction: column;
  position: relative;
  
}

.app_bottom{
  height: 89vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.app_bottom_center{
  width: 70%;
  height: 90%;
  
  display: flex;
  flex-direction: row;
}
.abc_left{
  width: 60%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
}
.abc_top{
  height: 10%;
  width: 100%;
 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.abc_top1{
  height: 50%;
  width: 100%;
 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.abc_top2{
  height: 40%;
  width: 100%;
  
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.abc_top2 > p{
  text-align: left;
  color: rgba(66, 66, 66, 0.634);
  font-family: 'Roboto';
  font-size: 1.1vw;
}
.abc_right_top1{
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: flex-start;
  align-items: flex-start;
 
  
}
.abc_right_top2{
  
  display: flex;
  width: 100%;
  height: 50%;
  
  
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
 
}
.abc_right_top33{
  display: inline-block;
  display: flex;
  width: 100%;
 
  flex-direction: column;
  
  
  justify-content: flex-start;
  align-items: flex-start;
 
 
}
.abc_right_top33 > p{
text-align: left;
margin: 0;
margin-top: 10px;
color: rgba(66, 66, 66, 0.634);
font-size: 1vw;
}
.major_holder{
height: 30px;
margin: 0;
padding: 0;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
background-image: linear-gradient(43deg, #4159d0d8 0%, #c850c0cd 100%, #FFCC70 100%);
border-radius: 10px;
flex-shrink: 0;
margin-bottom: 5px;
}
.major_holder > p{
  font-size: 1vw;
  text-wrap: nowrap;
  padding: 10px;
  color: white;
  font-family: 'Roboto';
}
.major_holder1{
  height: 30px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 10px;
  flex-shrink: 0;
  }
  .major_holder1 > p{
    font-size: 1vw;
    text-wrap: nowrap;
    padding: 10px;
    color: white;
    font-family: 'Roboto';
  }
.abc_major{
  margin-top: 0;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  margin: 0;
}
.abc_major > h3{
  
}
.clickhere{
  text-decoration: none;
  color: white;
  position: absolute;
  bottom: 50px;
  right: 100px;
  z-index: 1;
  font-family: 'Roboto';
}
.signinbutton{
  color: blue;
}
.signinbutton:hover{
 cursor: pointer;
}
.abc_right_top1_center{
  
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  
}
.dash{
  color: rgb(0, 0, 0);
}
.abc_right_top1_center > h2{
  font-family: 'Roboto';
  text-wrap: nowrap;
  font-size: 1.5vw;
}
.abc_top1 > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.abc_right{
  width: 35%;
  height: 33vw;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1),
              0px 4px 8px rgba(0, 0, 0, 0.1),
              0px 2px 4px rgba(0, 0, 0, 0.1);
              margin-top: 8vh;
  z-index: 5;
  position: relative;
}
.tourCenter{
  display: flex;
  flex-direction: column;
 
  align-items: center;
}
.upcoming_tour_holder{
  width: 600px;
  height: 150px;
  padding-bottom: 15px;
 
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  margin: 25px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.doneButton{
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 40px;
  font-size: 17px;
  background-color: transparent;
  border: 2px solid #6F42C1;
  color: #6F42C1;
  transition: 0.2s;
}
.doneButton:hover{
  background-color: #6F42C1;
  color: white;
  cursor: pointer;
}
.doneButtonCancel{
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 40px;
  font-size: 17px;
  background-color: white;
  border: 2px solid #6F42C1;
  color: #6F42C1;
  transition: 0.2s;
}
.doneButtonCancel:hover{
  background-color: #6F42C1;
  color: white;
  cursor: pointer;
}
.H_second_thirdd{
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  margin-top: 100px;
  flex-direction: column;
}
.compare{
  margin-top: 75px;
  width: 60%;
  height: 50px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.185);
}
.compare1{
  width: 60%;
  height: 200px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.185);
  margin-top: 15px;
}
.compareLeft{
  width: 60%;
  height: 100%;
  
}
.compareRight{
  width: 40%;
  height: 100%;
  display: flex;
}
.compareRightHalf{
  width: 50%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.why{
  font-size: 40px;
}
.uth_1{
  height: 100%;
  width: 25%;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.cal_holder{
  width: 100%;
  
  height: 100%;
  border-radius: 15px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 2px dashed rgba(0, 0, 0, 0.54);
  background-color: rgba(255, 255, 255, 0.253);
  
}
.cal_holder > h1{
  color: black;
  text-align: left;
  margin: 0;
  padding: 0;
  
  font-size: 20px;
  
}
.uth_2{
  height: 90%;
  width: 60%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

}
.uth_2 > p{
 text-wrap: nowrap;
 margin: 0;
  padding: 0;
  font-size: 12px;
  }
.uth_2 > h1{
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.uth_2 > h2{
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.689);
}
.uth_2 > button{
  margin: 0;
  padding: 0;
  width: 150px;
  height: 40px;
  background-color: white;
  color: #6F42C1;
  border: 2px solid #6F42C1;
  
  font-size: 15px;
  border-radius: 10px;
  transition: 0.2s;
}
.uth_2 > button:hover{
  margin: 0;
  padding: 0;
  width: 150px;
  height: 40px;
  background-color: #6F42C1;
  color: white;
  border: 2px solid #6F42C1;
  
  font-size: 15px;
  border-radius: 10px;
}
.abs_popup{
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  position: fixed;
}
.abs_popup_center{
  width: 100%;
  height: 100%;
  position: relative;
}
.popup_holder{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1),
  0px 4px 8px rgba(0, 0, 0, 0.1),
  0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow-y: auto;
  

}
.popup_holder_top{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup_holder_top > h1{
  font-size: 25px;
  text-align: left;
  margin-left: 25px;
}
.popup_holder_top > img{
  width: 35px;
  height: 35px;
  margin-right: 25px;
  background-color: rgba(0, 0, 0, 0.074);
  border-radius: 50%;
  padding: 5px;
  transition: 0.2s;
}
.popup_holder_top > img:hover{
  background-color: rgba(0, 0, 0, 0.149);
  border-radius: 50%;
}
.po{
  text-align: left;
  margin-left: 25px;
  width: 90%;
}
.uth_3{
  height: 90%;
  width: 30%;
 
  display: flex;
  flex-direction: row;
 align-items: flex-end;
 justify-content: flex-end;
 
}
.uth_3 > button{
  width: 45%;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
}
.uth_3_1{
  background-color: white;
  color: #c14264;
  border: 2px solid #c14264;
  font-family: 'Roboto';
  transition: 0.1s;
}
.uth_3_1_button{
  background-color: white;
  color: #c14264;
  border: 2px solid #c14264;
  font-family: 'Roboto';
  transition: 0.1s;
  width: 90%;
  margin-top: 5px;
  height: 45px;
  border-radius: 5px;
 
}
.uth_3_11{
  background-color: white;
  color: #c14264;
  
  font-family: 'Roboto';
 
}
.uth_3_1:hover{
  background-color: #c14264;
  color: white;
  border: 2px solid #c14264;
  font-family: 'Roboto';
}
.uth_3_1_button:hover{
  background-color: #c14264;
  color: white;
  border: 2px solid #c14264;
  font-family: 'Roboto';
}
.uth_3_2{
  background-color: #6F42C1;
  color: white;
  font-family: 'Roboto';
  border: 2px solid #6F42C1;
}
.abc_middle{
  width: 5%;
  height: 100%;
}
.H_first{
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 65vh;
  margin-top: 15vh;
}
.H_second{
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 70vh;
  position: relative;

}
.H_second_third{
  width: 33%;
  height: 60%;
  

}
.H_second_third1{
  width: 25%;
  height: 100%;
  

}
.third_1{
 
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
} 
.third_2{
  
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.third_11{
 
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
} 
.profile_bg{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.656)
}
.profile_bg1{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.656)
}
.profile_holder{
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile_holder1{
  width: 150px;
  height: 200px;
  display: flex;
  flex-direction: column;
  
}
.profile_bg1 > img{
  
  border-radius: 50%; 
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile_bg > img{
  
  border-radius: 50%; 
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile_side{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(80px) translateY(50px);
  background-color: rgb(255, 255, 255);
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_side > img{
  width: 110%;
  height: 110%;
  
}
.all-set-h2{
  margin-top: 50px;
  color: rgba(39, 39, 39, 0.805);
}
.all-set-div{
  margin-top: 25px;
  width: 300px;
  height: 60px;
  border: 2px solid #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 17px;
  color: #6F42C1;
  transition: .2s;
  
}

.all-set-div:hover{
  margin-top: 25px;
 
  background-color:  #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.all-set-div > p{
  font-size: 20px;
  color: #6F42C1;
}
.profile_bottom{
  margin-top: 25px;
  width: 100%;
  height: 40px;
  border: 1px solid #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_bottom > p{
  color:#6F42C1;
  font-size: 15px;
}
.profile_bottom1{
  margin-top: 10px;
  width: 150px;
  height: 40px;
  border: 1px solid #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_bottom1 > p{
  color:#6F42C1;
  font-size: 15px;
}

.third_22{
  
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
} 
.third_2 > h2{
  font-size: 25px;
  color: #282c34;
}

.third_3{
 
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
} 
.third_33{
 
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.third_33 > h2{
  font-size: 1.5vw;
}
.cap{
  width: 150px;
  height: 150px;
}
.circle_holder{
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.circle {
  width: 5px; /* Set the width */
  height: 5px; /* Set the height */
  border-radius: 50%; /* Make it a circle */
  background-color: rgba(0, 0, 0, 0.363); /* Background color */
  margin: 0 10px; /* Adjust the margin between circle and text */
  z-index: 5;
}
.capp{
  width: 120px;
  height: 120px;
}
.bl4_inputTextHolder{
  
  width: 200px;
  height: 75px;
  
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolder{
  margin-top: 50px;
  width: 500px;
  height: 75px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolderc{
  margin-top: 50px;
  width: 500px;
  height: 75px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolder_account{
  margin-top: 50px;
  width: 40%;
  height: 75px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolder1{
  margin-top: 20px;
  width: 500px;
  height: 75px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputBoxTextHolder{
  margin-top: 20px;
  width: 700px;
  height: 400px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  flex-shrink: 0;
}
.inputBoxTextHolder_bl4{
  margin-top: 50px;
  width: 100%;
  height: 125px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  flex-shrink: 0;
}
.inputBoxTextHolder11{
  margin-top: 20px;
  width: 90%;
  height: 400px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  flex-shrink: 0;
  
}
.inputBoxTextHolder111{
  margin-top: 20px;
  width: 100%;
  height: 300px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  flex-shrink: 0;
  
}
.inputBoxTextHolder1{
  margin-top: 50px;
  width: 700px;
  height: 100px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputBoxTextHolderPopup{
  width: 90%;
  height: 250px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputBoxTextHolder12{
  margin-top: 50px;
  width: 700px;
  height: 300px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputBoxTextHolder123{
  
  width: 100%;
  height: 300px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.check_img{
  width: 200px;
  height: 200px;
  margin-top: 0px;
  margin-left: 20px;
}
.compareLeft > h2{
  text-align: left;
  margin-left: 20px;
  font-family: 'Roboto';
}
.compareLeft > p{
  text-align: left;
  margin-left: 20px;
  width: 80%;
  color: rgba(128, 128, 128, 0.808);
  word-spacing: 2px;
  font-size: 1.05vw;
  font-family: 'Roboto'
}
.check{
  margin-top: 20px;
  width: 40px;
  height: 40px;
}
.check1{
  margin-top: 25px;
  width: 22px;
  height: 22px;
}
.account_profile_right{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.names{
  width: 80%;
  height: 50px;
  padding-bottom: 20px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 
 
}
.inputBoxTextHolder1_account{
  margin-top: 50px;
  width: 80%;
  height: 100px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  margin-left: 50px;
}
.inputTextHolder11{
  margin-top: 15px;
  width: 500px;
  height: 75px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolder2{

  width: 80%;
  height: 75px;
 
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
}
.inputTextHolderTop{
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.inputBoxTextHolderTop{
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.inputBoxTextHolderTop1{
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  
}
.inputBoxTextHolderTop1 > p{
  color: #807f7f;
  font-size: 15px;
  font-weight: 600;
}
.inputBoxTextHolderTop > p{
  color: #807f7f;
  font-size: 15px;
  font-weight: 600;
}
.inputTextHolderTop > p{
  color: #807f7f;
  font-size: 15px;
  font-weight: 600;
}

.inputText{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  padding-left: 10px;
  
  font-size: 15px;
 
   font-weight: 500;
}
.inputBoxText{
  width: 97%;
  height: 70%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  z-index: 0;
  border-width: 2px;
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
}
.inputBoxText1234{
  width: 100%;
  height: 70%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  z-index: 0;
  border-width: 2px;
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
}
.inputBoxText1{
  width: 95%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  border-width: 2px;
  
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
   overflow-y: hidden;
}
.inputBoxTextPopup{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  border-width: 2px;
  
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
   overflow-y: hidden;
}
.inputBoxText123{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  border-width: 2px;
  
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
   overflow-y: hidden;
}
.y{
  margin-top: 50px;
}
.inputBoxText1_1_1{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  border-width: 2px;
  
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
   overflow-y: hidden;
}
.inputBoxText_bl4{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224a41;
  border-radius: 10px;
  border-width: 2px;
  
  font-size: 15px;
   font-family:Arial, Helvetica, sans-serif;
   padding: 20px;
   font-weight: 500;
   overflow-y: hidden;
}

textarea:focus{
  outline: none;
}
.inputText1{
  width: 97%;
  height: 80%;
  border-style: dotted;
  border-color: #25224ae7;
  border-radius: 10px;
  
  
  font-size: 15px;
  text-indent: 10px;
   font-weight: 500;
}
.inputText1::placeholder{
  text-indent: 10px;
}
.inputText1c{
  width: 100%;
  height: 80%;
  border-style: dotted;
  border-color: #25224ae7;
  border-radius: 10px;
  
  
  font-size: 15px;
  text-indent: 10px;
   font-weight: 500;
}
.inputText1c::placeholder{
  text-indent: 10px;
}
.inputText:focus{
  
  outline: none;
}
input:focus{
  
  outline: none;
}
select{
  width: 30%;
  height: 70%;
  border-style: dotted;
  border-color: #25224a41;
}
.third_3 > p{
  font-size: 15px;
  text-overflow: wrap;
  color: #282c349d;
  font-weight: 300;
  width: 300px;
  line-height: 25px;
}
.H_third{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 10vh;
  position: relative;
  background-color:white;
}
.H_forth{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 50vh;
  position: relative;
  background-color: #3b3788;
  color: white;
}
.H_fifth{
  background-color:#325d94a1;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  height: 35vh;
  position: relative;
  
}
.H_fifth > h1{
  color: white;
}
.H_six{
 
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  height: 70vh;
  padding-top: 5vh;
  position: relative;
  background-color: white;
  background-color:#325d94a1;
  
}
.H_six_third{
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
 
  height: 90%;
 
  flex-direction: column;
  border: 2px solid white;
  border-radius: 10px;
 
   

}
.third_header{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third_header > h3{
  color: white;
}
.third_middle{
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.third_middle > img{
  width: 35px;
  height: 35px;
}
.third_middle > h3{
  font-weight: 300;
  color: rgba(0, 0, 0, 0.493);
  font-size: 1.3vw;
  color: white;
}
.H_seven{
  width: 80vw;
  display: flex;
 
  align-items: flex-start;
  flex-direction: column;
  height: 20vh;
  margin-top: 20vh;
  padding-bottom: 5vh;
  position: relative;
  background-color: white;
 
}
.H_seven > h2{
  font-weight: 400;
}
.H_seven > h4{
  font-weight: 400;
}
.H_seven > a{
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}
.H_fifth > h1{
  font-size: 50px;
}

.second_header{
height: 30vh;
width: 100vw;
display: flex;
justify-content: center;
align-items: flex-end;
font-size: 30px;
margin-top: 100px;
}
.H_first_left{
  width: 40vw;
  height: 100%;
  margin-top: 100px;
}
.H_first_right{
  width: 650px;
  height: 100%;
  margin-top: 50px;
 
 display: flex;
 flex-direction: row;
 position: relative;
}
body{
  z-index: 1;
}
.container{
 
 
 width: 33%;
 height: 100%;
 margin: 5px;
 display: flex;
 flex-direction: column;


}
@keyframes container_anim {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.container1{
  
 overflow: auto;
 width: 90%;
 background-color: white;
 height: 400px;
 overflow-x: hidden;
 box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1),
              0px 4px 8px rgba(0, 0, 0, 0.1),
              0px 2px 4px rgba(0, 0, 0, 0.1);
              padding: 25px;
}
.NavHolder{
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 10vh;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  overflow-y: hidden;
  position: relative;
}
.NavHolder_1{
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 10vh;
  margin-left: 17px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hamburger{
  display: none;
}


.hamburger_img{
  width: 100%;
  height: 100%;
}
.NavHolder1{
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 30vh;
  justify-content: space-between;
  align-items: center;
 
}
.account_holder{
  width: 100vw;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;

}
.a2{
  width: 100%;
  height: 100%;
}
.account_holder_inner{
  width: 80vw;
  height: 100%;
  display: flex;
  
}
.account_content_inner::-webkit-scrollbar {
  display: none;
  height: 0px;
}
.account_nav{
  width: 20%;
  height: 100%;
 /* border-right: 1px solid rgba(128, 128, 128, 0.555); */
}
.account_nav_inner{
  padding-top: 100px;
}
.account_nav_text:hover{
  cursor:pointer;
  color:#6F42C1;
}

.account_nav_text{
  color: rgba(82, 82, 82, 0.653);
  font-weight: 400;
  margin-top: 30px;
  font-size: 19px;
  transition: 0.2s;
}
.account_content_header{
  padding-top: 100px;
  font-weight: 400;
  
}
.account_content_header1{
  
  font-weight: 400;
  
}
.account_nav_inner > h1{
  color:#6F42C1;
  font-weight: 400;
  font-size: 30px;
}
.account_nav_text{
  text-align: left;
  
}
.account_content{
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: visible;
  padding-bottom: 50px;
}
.account_content1{
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.account_content_inner1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: flex-start;
  overflow-x: hidden;
  padding-left: 50px;
  margin-top: 100px;
}
.dashboard{
  width: 200px;
  height: 50px;
  background-color: white;
  border: 2px solid #6460ac;
  color:#6460ac;
  font-size: 15px;
  margin-top: 10px;
  transition: 0.2s;
  margin-bottom: 50px;
}
.left{
  text-align: left;
}
.cp{
  margin-top: 50px;
  text-align: left;
}
.dashboard:hover{
  background-color: #6460ac;
  color: white;
  cursor: pointer;
}
.account_content_inner{
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: flex-start;
  overflow-x: hidden;
}
.profile1{
  width: 100%;
  height: 250px;
  border-bottom: 2px solid #95959564;
  display: flex;
 
  padding-bottom: 50px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.profile11{
  width: 100%;
  height: 500px;
  
  display: flex;
 
 
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 25px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.profile22{
  width: 100vw;
  height: 800px;
  
  display: flex;
 
 
  align-items: center;
  justify-content: flex-start;
 
  margin-top: 10px;
  margin-bottom: 50px;
}
.profile2{
  width: 100%;
  height: 500px;
  border-bottom: 2px solid #95959564;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  padding-bottom: 15px;
  margin-top: 10px;

}
.searchBox{
  width: 400px;
  height: 55px;
  position: relative;

  
}
.searchBoxInput{
  width: 90%;
  height: 100%;
  border: 1px solid rgba(128, 128, 128, 0.321);
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding-left: 40px;
  color: rgba(128, 128, 128, 0.815);
  font-size: 17px;
}

.feedTop{
  width: 100vw;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(128, 128, 128, 0.395);
  padding-bottom: 10px;
  overflow-y: hidden;
  position: relative;
}
.feedTopCenter{
  width: 77vw;
  height: 60px;
  
  position: absolute;
  left: 50%;
  top: 12vh;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.configure{
  width: 150px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.504);
  border-radius: 25px;
}
.results_container{
  position: absolute;
  left: 50%;
  top: 21vh;
  transform: translateX(-50%);
  pointer-events: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  
}
.results_container > p{
  width: 400px;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

}
.feed1{
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.feed1_1{
  width: 78vw;
  height: 10vh;
  
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feed1_2{
 
  width: 78vw;
  height: 110%;
  margin-top: 25px;
 
}
.feed1_2_top{
  width: 78vw;
  height: 10%;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.feed1_2_top > p{
  font-size: 20px;
  font-weight: 600;
  color: black;
}
.feed1_2_bottom{
  width: 78vw;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 50px;
}
.feed1_1_half_left{
  width: 50%;
  height: 100%;
  text-align: left;
 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.feed1_1_half_left > p{
  font-weight: bold;
  font-size: 25px;
}
.configure_box{
  width: 100px;
  height: 40px;
  
  display: flex;
  border-radius: 5px;
  margin-right: 50px;
}
.feed1_1_half_right{
  width: 50%;
  height: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.person{
  margin-bottom: 20px;
  max-width: calc(25% - 50px);
  height: 300px;
  
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /*box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  background: rgba(255, 255, 255, 0.15); 
  backdrop-filter: blur(10px); */ /* Apply blur effect */ 
 transition: 0.2s;
 flex: 1 0 calc(25% - 50px);
 margin-right: 0;
}
.person1{
  margin-bottom: 20px;
  max-width: calc(25% - 50px);
  height: 300px;
 
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  
 
 flex: 1 0 calc(25% - 50px);
 margin-right: 0;
}
.person:hover{
  transform: scale(1.05);
  cursor: pointer;
}
@media (max-width: 1500px) { /* Adjust breakpoint as needed */
  .person {
    max-width: calc(33.3% - 50px);
    flex: 1 0 calc(33.3% - 50px); /* Adjust width for three items in a row */
  }
}
@media (max-width: 1000px) { /* Adjust breakpoint as needed */
  .person {
    max-width: calc(50% - 50px);
    flex: 1 0 calc(50% - 50px); /* Adjust width for three items in a row */
  }
}
@media (max-width: 500px) { /* Adjust breakpoint as needed */
  .person {
    max-width: 80%;
    flex: 1 0 80% /* Adjust width for three items in a row */
  }
}
.feed_bottom_nav{
  width: 100vw;
  height: 8vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.feed_nav_bottom_button{
  width: 50px;
  height: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.feed_nav_bottom_button > p{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.person_img_holder{
  width: 100%;
  height: 60%;
  
}
.person_img_holder > img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-radius: 15px;
}
.person_img_footer{
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
 /* Hides any overflowed content */
    
}
.person_img_footer > h2{
  height: 30px;
  
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 20px;
}

.img_p{
  height: 30px;
  margin-left: 10px;
  font-size: 12px;
  color: rgba(79, 79, 79, 0.582);
  font-family: 'Roboto';
  height: 30px;
  text-align: left;
  font-weight: 300;
  width: 80%;
  margin-top: 5px;
}
.configure_box_right > p{
  font-weight: 400;
 
}
.configure_box_left{
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.configure_box_left > img{
  width: 60%;
  height: 50%;
}
.configure_box_right{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchBoxIcon{
  width: 40px;
  height: 40px;
  
  border-radius: 50%;
  background-color: #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  margin-right: 20px;
}
.filterBoxIcon{
  width: 40px;
  height: 40px;
  
  border-radius: 50%;
 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
 
}
.searchBoxIcon:hover{
  width: 43px;
  height: 43px;
  
  border-radius: 50%;
  background-image: linear-gradient(43deg, #4159d0d8 0%, #c850c0cd 100%, #FFCC70 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  margin-right: 20px;
}
.searchBoxIcon > img{
  width: 40%;
  height: 40%;
}
.filterBoxIcon > img{
  width: 40%;
  height: 40%;
}
.account_header{
  color: gray;
  font-weight: bolder;
 
  font-size: 17px;
  

}
.NavHolder2{
  display: flex;
  flex-direction: row;
  width: 40vw;
  height: 30vh;
  justify-content: left;
  align-items: center;
 
}
.account_selected{
  color: #6F42C1;
  margin-left: 20px;
}
.NavHolder3{
  display: flex;
  flex-direction: row;
  width: 40vw;
  height: 30vh;
  justify-content: right;
  align-items: center;
  
}
.home{
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.home1{
  position: absolute;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.nav{
  text-decoration: none;
  font-size: 17px;
  margin: 40px;
  
  color: rgba(0, 0, 0, 0.89); 
   text-wrap:nowrap;
}
.H_first_left_container{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  text-align: left;
 
}
.H_first_left_container > h1{
  font-size: 45px;
}
.H_first_left_container > p{
  font-size: 17px;
  font-weight: 350;
  color: rgba(0, 0, 0, 0.623);
  width: 500px;
  
}
.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}
.next{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bio{
  width: 500px;
  height: 5vw;
  font-size: 20px;
  padding-left: 15px;
  padding-top: 15px;
}
.guideTop{
  width: 100vw;
  height: 300vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.guideHeader{
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guideHeader > h1{
  font-size: 50px;
  font-weight: 400;
}
.guideHeader1{
  width: 100vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guideHeader1 > h1{
  font-size: 30px;
  font-weight: 400;
}
.suggestions li {
  padding: 10px;
  border-top: 1px solid #ccc;
  cursor: pointer;
  z-index: 10;
}

.App{
  overflow-x: hidden;
  font-family: 'Roboto';
  
 
}
.App22{
  overflow-x: hidden;
  overflow-y: hidden;
 
}
.App11{
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.suggestions li:hover {
  background-color: #f0f0f0;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
  cursor: pointer;
}

tr:nth-child(even) {background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}
.no-suggestions {
  color: #999;
  padding: 10px;
}
.H_first_left_container > button{
  width: 250px;
  height: 65px;
  background-color: white;
  color: white;
  font-size: 17px;
  border-radius: 0px 40px 40px 40px;
  transition: .2s;
  font-family: 'Montserrat', sans-serif;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 100%, #FFCC70 100%);
  border: none;
 
  
}
.book_button{
  margin-bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
}

.book_button > button{
  width: 100%;
  height: 100%;
  background-color: #6F42C1;
  border: 2px solid #6F42C1;
  color: white;
  font-size: 17px;
  font-family: 'Roboto';
  transition: 0.2s;
}
.book_button > button:hover{
 transform: scaleY(1.1);
}
.H_first_left_container > button:hover{
 
  
  color: white;
  width: 300px;
}
.guideSpace{
  width: 90vw;
  height: 10vh;
  
}
.guideRow{
  width: 90vw;
  height: 40vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.guideRowSide{

 width: 10vw;
 height: 100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.guideRowSideTop{
height: 5vw;
width: 100%;

display: flex;
justify-content: center;
align-items: center;
}
.guideCircle{
width: 5vw;
height: 5vw;
border-radius: 50%;
border: 2px solid #3b37888a;
position: relative;
display: flex;
justify-content: center;
align-items: center;
}
.guideCircle > p{
  position: absolute;
  font-size: 40px;
  color: rgb(136, 136, 136);
}
.guideRowSideBottom{
  height: calc(100% - 5vw);
width: 100%;

display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: column;
}
.guideRowSideBottom > div{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #3b37887a;
}
.guideRowMiddle{
  width: 80vw;
  height: 100%;

display: flex;
flex-direction: column;
justify-content: flex-start;

}
.guideCenterHolder{
height: 5vw;
width: 100%;

display: flex;
justify-content: center;
gap: 20px;

}
.guideName{
width: 300px;
height: 100%;
border: 2px solid black;
border-radius: 20px;
font-size: 25px;
padding-left: 15px;
}
.university{
  width: 470px;
  height: 5vw;
  border-radius: 20px;
  font-size: 25px;
  padding-left: 15px;
}
.guideDate{
  width: 150px;
  height: 100%;
  border: 2px solid black;
  border-radius: 20px;
  font-size: 15px;
}
.guideCenterHolder1{
  height: calc(100% - 5vw);
  width: 100%;
 z-index: -1;
  }
.img{
 position: absolute;
 width: 400px;
  right: -75px;

 
  animation-name: example;
  animation-duration: 30s;
  animation-timing-function: linear;
  
  top: 500px;
}
:root{
  --period: 50s;
}
.img1{
  position: absolute;
  width: 100%;
   
   height: 320px;
   top: 700px;
   animation-name: one;
   animation-duration: var(--period);
   animation-delay: 5s;
   animation-timing-function: linear;
   border-radius: 10px;
   object-fit: cover;
 }
 .img_new{
  height: 180px;
  min-height: 150px;
  width: 100%;
   top: 700px;
   object-fit: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 15px;
   margin-bottom: 15px;
   transition: 0.5s;
 }

 .changeSizeClass{
  animation: changeSize 1s ease-in-out;
 }
 @keyframes changeSize {
  0% {
    transform: scale(1);
    
  }
  50% {
    transform: scale(0.2);
     
  }
  100% {
    transform: scale(1) rotate(360deg);
    
  }
}
 .img11{
  position: absolute;
 
  height: 320px;
  width: 100%;
   top: 700px;
   animation-name: onee;
   animation-duration: var(--period);
   animation-delay: 5s;
   animation-timing-function: linear;
   border-radius: 10px;
   object-fit: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 15px;
 }
 .img_inner{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
 }
 .img_inner > h1{
  position: absolute;
  bottom: 20px;
  color: white;
  left: 20px;
  font-size: 17px;
  text-wrap: nowrap;
 }
 .img_inner > h2{
  position: absolute;
  bottom: -5px;
  color: white;
  left: 20px;
  font-size: 16px;
  text-wrap: nowrap;
 }
 .img_inner > img{
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
 
 }
 .img22{
  animation-name: twoo;
  animation-duration: var(--period);
  animation-delay: 5s;
}
 .img2{
   animation-name: two;
   animation-duration: var(--period);
   animation-delay: 5s;
 }
 .img3{
   animation-name: three;
   animation-duration: var(--period);
   animation-delay: 5s;

 }
 .img33{
  animation-name: threee;
  animation-duration: var(--period);
  animation-delay: 5s;

}
.img44{
 
  animation-name: up;
  animation-duration: var(--period);
  animation-delay: 5s;
  
 }
 .img4{
 
  animation-name: down;
  animation-duration: var(--period);
  animation-delay: 5s;
  
 }
 .img5{
  animation-iteration-count: infinite;
  animation-name: down;
  animation-duration: var(--period);
  animation-delay: calc(var(--period) / 3);
 }
 .img6{
  animation-iteration-count: infinite;
  animation-name: down;
  animation-duration: var(--period);
  animation-delay: calc(2 * var(--period) / 3);
 }
 .img7{
  animation-iteration-count: infinite;
  animation-name: down;
  animation-duration: var(--period);
  animation-delay: var(--period);
 }
 .img55{
  animation-iteration-count: infinite;
  animation-name: up;
  animation-duration: var(--period);
  animation-delay: calc(var(--period) / 3);
 }
 .img66{
  animation-iteration-count: infinite;
  animation-name: up;
  animation-duration: var(--period);
  animation-delay: calc(2 * var(--period) / 3);
 }
 .img77{
  animation-iteration-count: infinite;
  animation-name: up;
  animation-duration: var(--period);
  animation-delay: var(--period);
 }

.nav:hover{
  color: purple;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}
@keyframes fadeout {
  0% {background-color: black;}
  100% {background-color: white;}
}
@keyframes fadein {
  0% {background-color: white;}
  100% {background-color: black;}
}
@keyframes example {
  0% {top: -400px;}
  100% {top: 600px;}
}
@keyframes one{
  0% {top: -165px;}
  100% {top: 835px;}
}
@keyframes onee{
  0% {top: -115px;}
  100% {top: -1115px;}
}
@keyframes two{
  0% {top:  165px;}
  100% {top: 1165px;}
}
@keyframes twoo{
  0% {top:  215px;}
  100% {top: -785px;}
}

@keyframes down{
  0% {top: -500px;}
  100% {top: 500px;}
}
@keyframes up{
  0% {top: 550px;}
  100% {top: -450px;}
}



.dayHolderHolder{
  display: flex;

  align-items: center;
  width: 100vw;
 
  flex-direction: column;
}
.dayHolderHolder1{
  margin-top: 50px;
  display: flex;
  
  align-items: center;
  width: 100vw;
  flex-direction: column;
}
.dayHolderHolder11{
  margin-top: 50px;
  display: flex;
  
  align-items: flex-start;
  width: 100vw;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
}
.bottomRight {
 
  width: 100%;
  height: 10vh;
  position: absolute;
  bottom: -5px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
 
}
.topRight {
 
  width: 100%;
  height: 10vh;
  position: absolute;
  top: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
 
}
.half{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black
}
.dayHolderTop{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
}
.dayHolderTop > p{
  color: #807f7f;
  font-size: 18px;
  font-weight: 600;
}
.dayHolderBottom{
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.half1{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
.myCalendar .react-calendar__tile--now {
  background: inherit;
  color: inherit;
  border: none;
}
.yct{
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0;
}
.yct1{
  margin-top: 50px;
  margin-bottom: 25px;
  color:#6460ac;
}
.dayHolder{
  margin-bottom: 20px;
  width: 40%;
  height: 100px;
  display: flex;
  flex-direction: column;
}
.cal{
  width: 500px;
}
.abs{
  position: absolute;
  top: 12vh;
  left: 50%;
  transform: translateX(-50%);
}
.abs1{
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  width: 33vw;
  height: 11vh;
  box-shadow: 10px 10px 10px rgba(212, 212, 212, 0.603);
  border-radius: 40px;
  background-image: linear-gradient(43deg, #4159d0d8 0%, #c850c0cd 100%, #FFCC70 100%);
}
.not-selected{
  border: 1px solid #6F42C1;
  width: 80px;
  height: 30px;
  font-size: 15px;
  text-wrap: nowrap;
  background-color: white;
  color: #6F42C1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
}

.selected{
  border: 1px solid #6F42C1;
  width: 80px;
  height: 30px;
  font-size: 15px;
  text-wrap: nowrap;
  background-color: #6F42C1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
}
.FeedCard {
  border: 1px solid black;
  width: 200px;
  
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.call{
  position: absolute;
  bottom: 25px;
  right: 50px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.selectedCategoriesHolder > p{
 font-weight: 500
}
.inputTextHolderc{
  width: 100%;
}
@media (max-width: 1200px) {
  .container{
 
 
    width: 50%;
    height: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
   
   
   }
   #last_container{
    display: none;
   }
}
@media (pointer:none), (pointer:coarse), (max-width: 600px) {
    .call{
      display: none;
    }
  .hamburger{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav{
    display: none;
  }
  .account_header{
    color: gray;
    font-weight: bolder;
    margin-top: 100px;
    font-size: 17px;
    
  }
  .svgg{
    overflow: hidden;
  }
  body{
    overflow-x: hidden;
  }
  .H_first_right{
    display: none;
  }
  .H_first_left{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 600px;
    margin-top: 150px;
  }
  .dayHolderBottom{
    width: 100%;
    height: 70%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Maximum of 3 columns */
    gap: 10px; 
  }
  .profile22{
    width: 100vw;
    height: 1200px;

    display: flex;
    margin-top: 150px;
   
    align-items: center;
    justify-content: center;
   
    
    
  }
  .AppHome{
    overflow-x: hidden;
    position: relative;
  }
  .H_first_left_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .H_first_left_container > button{
    width: 300px;
    border-top-left-radius: 40px;
  }
  .H_first_left_container > button:hover{
    width: 350px;
  }
  .App{
    overflow-x: hidden;
  }
  .svgg{
    height: 400px;
  }
  .H_second{
    height: 1400px;
    flex-direction: column;
  }
  .H_second_third{
    width: 100%;
    height: 33%;
  }
  .second_header{
   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .third_3{
    padding-bottom: 50px;
  }

  .abs1{
    width: 75vw;
  }
  .H_six{
    height: 1000px;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .H_six_third{
    width: 90%;
    height: 27%;
  }
  .third_middle > h3{
    font-size: 15px;
  }
  .H_seven{
    justify-content: center;
    align-items: center;
  }
  .H_second_thirdd{
    margin-bottom: 200px;
  }
  .compare{
    width: 95%;
  }
  .compare1{
    width: 95%;
    height: 300px;
  }
  .compareLeft > p{
    font-size: 15px;
  }
  .compareRightHalf > p{
    text-wrap: nowrap;
  }
  .compareLeft{
    width: 55%;
  }
  .compareRight{
    width: 45%;
  }
  .menu_nav{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 10;
  }
  .feed1_2_bottom{
    justify-content: center;
  }
  .feed1_2_top{
    justify-content: center;
  }
  .menu_nav_inner{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menu_nav_exit{
    position: absolute;
    top: 15px;
    right: 30px;
    width: 50px;
    height: 50px;
  }
  .nav_inner_holder{
    margin-top: 80px;
    width: 80%;
    height: 90%;
    
  }
  .nav_inner_holder > p{
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
    padding-top: 25px;
    padding-bottom: 10px;
    font-size: 20px;
  }
  .feed1_1_half_left{
    width: 50%;
    justify-content: center;
  }
  .feed1_1_half_right{
    width: 50%;
    justify-content: flex-end;
    display: flex;
   
    align-items: center;
  }
  .configure_box{
    position: relative;
    margin: 0;
  }
  .app_bottom{
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .app_bottom_center{
    width: 100%;
    height: 90%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .svggg{
    display: none;
  }
  .abc_left{
    width: 85%;
  }
  
  .abc_top2 > p{
    font-size: 15px;
  }
  .abc_right{
    width: 85%;
    height: 400px;
    margin-bottom: 20px;
  }
  .abc_right_top1_center > h2{
    font-size: 20px;
  }
  .major_holder > p{
    font-size: 18px;
  }
  .major_holder1 > p{
    font-size: 18px;
  }
  .abc_right_top33 > p{
    font-size: 15px;
  }
  .bl_3_bottom{
    width: 100%;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .book_time{
    flex-shrink: none;
    height: 50px;
    width: 60px;
    min-width: 90px;
    
  }

  .bl_3{
    height: 300px;
    padding-bottom: 100px;
    align-items: center;
  }
  .booking_container{
    flex-direction: column;
  }
  .booking_left{
    width: 100%;
  }
  .bl4_holder{
    height: 300px;
    flex-direction: column;

  }
  .bl4_inputTextHolder{
    width: 100%;
  }
  .bl_4{
    height: 600px;
  }
  .booking_right{
    width: 100%;
    display: flex;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .price_container{
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;
    
      border: 1px solid rgba(128, 128, 128, 0.3);
     
      top: 0;
      right: 0;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .App_booking{
    height: 100%;
    display: flex;
     margin: 0;
     padding: 0;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 50px;
  }.account_content1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
  .guideTopNavHalf > h3{
    margin-left: 50px;
  }
  .c_center > p{
    width: 90%;
  }
  .guideContentHolder{
    width: 90%;
  }
  .inputTextHolderc{
    width: 100%;
  }
  .BottomNav{
    width: 80%;
  }
  .selectedCategoriesHolder{
    width: 90%;
    height: 6vh;
    margin-top: 25px;
   
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    position: relative;
  }
  .categoriesHolder{
    width: 90%;
    height: 40vh;
    margin-top: 10px;
   
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
   
  }
  .inputTextHolder1{
    margin-top: 20px;
    width: 100%;
    height: 75px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    
    align-items: flex-start;
  }
  .inputBoxTextHolder{
    width: 100%;
    height: 300px;
  }
  .inputBoxTextHolder1{
    width: 100%;
  }
  .dayHolderHolder1{
    width: 90%;
    justify-content: center;
    align-items: baseline;
  }
  .dayHolderHolder11{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .dayHolder{
    margin-bottom: 20px;
    width: 90%;
    height: 200px;
    display: flex;
    flex-direction: column;
  }
  .not-selected{
    margin: 5px;
    width: auto;
    text-wrap: nowrap;
    height: 40px;
  }
  .selected{
    margin: 5px;
    width: auto;
    height: 40px;
  }
  .inputBoxTextHolder12{
    width: 100%;
  }
  .upcoming_tour_holder{
   
    width: 95%;
   
  }
  .uth_1{
    width: 30%;
  }
  .uth_2{
    width: 40%;
  }
  .abs_popup_center{
   
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .account_holder_inner{
    flex-direction: column;
    align-items: center;
    
    width: 100%;
  }
  .account_nav_inner{
    height: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .account_nav_inner > h1{
    display: none;
  }
  .account_nav_inner > h2{
    margin: 20px;
    text-wrap: nowrap;
    width: auto;
    height: 50px;
    font-size: 17px;
    white-space:nowrap;
    margin-top: 50px;
    
  }
  .account_nav{
    height: 150px;
    width: 100%;
    margin-bottom: 0;
  }
  .account_selected{
   
    margin: 0;
    padding: 0;
  }
  .account_content{
    height: 100%;
    
  }
  .account_content_header{
    padding-top: 0;
  }
  .account_content_inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile1{
    flex-direction: column;
    height: 500px;
    align-items: center;
  }
  .profile_bg1{
    width: 100%;
  }
  .account_profile_right{
    width: 100%;
    margin-top: 50px;
  }
  
  .names{
    margin: 0;
    width: 100%;
  }
  .inputBoxTextHolder1_account{
    margin-left: 0;
    width: 100%;
  }
  .left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .left > p{
    text-align: center;
    width: 80%;
  }
  
  .account_content_inner1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    overflow-x: hidden;
    padding-left: 0px;
    margin-top: 100px;
  }
  .H_first_left_container{
    text-align: center;
  }
  .cal{
    width: 100%;
  }
  #asd{
    margin-top: 150px;
  }
  .clickhere{
    display: none;
  }
}